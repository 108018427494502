import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const Products = props => {
  // const team = props.data.team.edges;
  // const { intro } = props.data;
  // const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  return (
    <Layout bodyClass="page-teams">
      <SEO title="Produktai" />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <h1>Produktai</h1>
              <p>Apsauginė signalizacija – tai sistema leidžianti pakankamai patikimai apsaugoti žmogaus turtą. Priklausomai nuo sistemos sudėtingumo signalizacija reaguoja į durų ir langų atidarymą, konstrukcijų ardymą, judėjimą, stiklo dūžimą, elektros įtampos dingimą ir kt.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {props.data.products.edges.map(({ node }) => (
            <div key={node.id} className="col-12 col-md-6 mb-2">
              <div className="team team-summary team-summary-large">
                <div className="team-meta">
                  <h2 className="team-name">{node.title}</h2>
                  <ul className="team-description">
                    {node.items.map((item, i) => <li key={i}>{item}</li>)}
                  </ul>
                </div>
                <div className="team-content">
                  {/* <ul>
                    {node.items.map((item, i) => <li key={i}>{item}</li>)}
                  </ul> */}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="row pt-6 pb-6">
          {team.filter(edge => (!edge.node.frontmatter.promoted)).map(({ node }) => (
            <div key={node.id} className="col-12 col-md-6 mb-2">
              <div className="team team-summary">
                {node.frontmatter.image && (
                  <div className="team-image">
                    <img alt={`photo of ${node.frontmatter.title}`} className="img-fluid mb-2" src={node.frontmatter.image} />
                  </div>
                )}
                <div className="team-meta">
                  <h2 className="team-name">{node.frontmatter.title}</h2>
                  <p className="team-description">{node.frontmatter.jobtitle}</p>
                  {node.frontmatter.linkedin && (
                    <a target="_blank" href="{{ .Params.Linkedinurl }}">LinkedIn</a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <br />
      <br />
      <div className="row justify-content-center">
        <div className="col-auto">
          <h2>Nerandate to ko ieškote?</h2>
        </div>
        <div className="col-auto">
          <Link className="button button-primary" to="/kontaktai/">Išsiūsti užklausą</Link>
        </div>
      </div>
      <br />
      <br />
      <br />

    </Layout>
  );
};


export const query = graphql`
  query ProductsQuery {
    products: allProduktaiJson {
      edges {
        node {
          id
          title
          items
        }
      }
    }
  }
`;

export default Products;
